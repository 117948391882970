import Custom500 from './500';
import { Metadata, PageContainer, CMSModule, BookingDropdown, ContactPopup } from 'components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageData, wrapper } from '../store/index';
import Header from '@/modules/Header';
import { getHost, getProtocol, getVirtualHost } from 'utils';

const Index = ({ errorCode, errorMessage, metadata, moduleContent, footer, header }) => {
  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  let slimHeroClass = header?.hotelNavigation
    ? '[&_.HeroSlimCenterAlignedModule]:lg:h-[calc(100vh-167px)]'
    : '[&_.HeroSlimCenterAlignedModule]:lg:h-[calc(100vh-104px)]';

  if (moduleContent?.find((module) => module.moduleName === 'AvailabilityBarModel')) {
    slimHeroClass =
      slimHeroClass +
      ' [&_.HeroSlimCenterAlignedModule_.text-wrapper]:pb-[63px] [&_.HeroSlimCenterAlignedModule_.text-wrapper]:md:pb-[71px] [&_.HeroSlimCenterAlignedModule_.text-wrapper]:lg:pb-[84px]';
  }

  return (
    <>
      <Metadata metadata={metadata} />
      <BookingDropdown availabilityData={header.availabilityBar} />
      <ContactPopup contactDetails={header.contactDetails} />
      <PageContainer className={slimHeroClass}>
        <Header
          header={header}
          metadata={metadata}
          filledHeader={moduleContent && moduleContent[0] && moduleContent[0].moduleName !== 'HeroCenterAlignedModule'}
        />

        {moduleContent?.map((module) => (
          <CMSModule key={module.moduleId} module={module} />
        ))}

        <CMSModule module={footer} />
      </PageContainer>
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;
  const host = getHost(req);
  const protocol = getProtocol(req);

  // workaround for missing assets spamming the API
  if (resolvedUrl.startsWith('/_next/')) {
    res.statusCode = 404;
    res.end();
    return;
  }

  let errorCode,
    errorMessage = null;
  try {
    const fetchUrl = new URL(`${protocol}//${host}/api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl}`);
    fetchUrl.pathname = fetchUrl.pathname.endsWith('/') ? fetchUrl.pathname.slice(0, -1) : fetchUrl.pathname;

    const dataRes = await fetch(fetchUrl.href, {
      keepalive: true,
      redirect: 'manual',
    });

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');

    if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix the problem.";
      res.statusCode = 500;
      console.error('[MP] BE Internal Server Error');
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'Page not found';
        res.statusCode = 404;
      } else if (errorCode >= 300 && errorCode < 400) {
        console.warn('Redirecting to:', dataRes.headers.get('Location'));
        res.writeHead(errorCode, {
          Location: dataRes.headers.get('Location'),
        });
        res.end();
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix the problem.";
        res.statusCode = 500;
        const responseText = await dataRes.text();
        console.error('[MP] BE response not JSON Object. contentType:', contentType, 'responseText:', responseText);
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];

      store.dispatch(
        setPageData({
          isRobot,
          pageId: data?.metadata?.pageId,
          host,
          virtualHost: getVirtualHost(req),
          protocol,
          siteTheme: data?.metadata?.siteTheme,
        }),
      );

      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') || data?.metadata?.doNotCache ? 0 : process.env.CDN_CACHE_MAX_AGE || 7200}`,
      );

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      return {
        props: {
          ...data,
          ...translations,
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error('[MP]', error);
    errorMessage = error.message;
    res.statusCode = 500;
  }

  return {
    props: { errorCode, errorMessage },
  };
});
